import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { parse, stringify } from 'query-string';
import track from 'react-tracking';
import { DefaultLayout as ProductListingPage } from '../../components/product-listing-page';
import { plpActions, plpSelectors } from '../../redux/product-listing-state';
import { fetchUserFromSF } from '@ynap/user-state';
import { getLocale, getPreviewDate } from 'app/redux/selectors';
import { getPLPSchemaMetaData } from '../../utils/getSchemaMetaData';
import { generateListingUrl, getFilterGroup, shouldRefetch, dataFetched, triggerWebpushNotification } from './helpers';
import { MetaTags } from '@ynap/meta-tags';
import { RetryErrorPage } from '@ynap/error-page';
import { StatusPage } from '../../components/StatusPage';
import { raygun } from '@ynap/clientside-monitoring';
import { trackPageView } from '../../analytics';
import { getCategory } from '../../analytics/plp';
import { Redirect } from 'react-router-dom';
import { checkRedirect, clientSideRedirect, checkLegacyRedirect, getSeoRedirect, getSearchRedirect, getSaleRedirect } from './redirect';
import { scrollIntoView } from '@ynap/scroll-utils';
import PLPNotify from './PLPNotify/PLPNotify';
import featuresList from '../../features';
import { FeatureSelectorProviderWrapper } from '@ynap/feature-selector';
import { withOptimizely, trackPage as trackPageOptimizely } from '@ynap/optimizely-utils';
import FavouriteDesigner from '../../components/designeraz/FavouriteDesigners/FavouriteDesigner';
import Onboarding from '../../components/designeraz/FavouriteDesigners/Onboarding';
import { shouldEnableWebpush } from './shouldEnableWebpush';

const {
    updateSelectedSchema,
    checkViewPagePermissions,
    fetchMore,
    updatePageNumber,
    unsetSrcoll,
    setWindowPosition,
    setOptimizelyAlgorithmicSortReady,
    fetchProductSiblings,
} = plpActions;

const {
    getFilters,
    getPaginationData,
    getOrderBy,
    getSelectedCategory,
    getCategoryLabels,
    getProductLists,
    getHeaderAndSEOData,
    getContent,
    getContentHeader,
    getFounditContent,
    getLoading,
    getForceLogin,
    getPageVisibility,
    getMetaData,
    getSearchTermView,
} = plpSelectors;

function mapStateToProps(state, props) {
    const { location, match, pathParam } = props;
    const { config, notificationState, userState, plp, appState } = state;
    const { tonMenswear } = config.head;
    const { notification } = notificationState;
    const { user } = userState;
    const { response, loading, appLoading, error, isDefaultOrderBy, optimizelyAlgorithmicSortReady, siblingsProducts } = plp.listing;
    const { prevPath, country } = appState;
    const { legacyRedirect, legacyRedirectUrl } = checkLegacyRedirect(state?.config?.head?.redirects?.legacyRedirect, location);
    const currentPage = parseInt(parse(location?.search)?.pageNumber) || 1;
    const responseStatus = response?.status;
    const isTONMenswear = tonMenswear?.enabled && location.pathname.includes('/mens/');

    //if there is no response we need to skip the mapping and return default, so it can refetch the data
    if (!response || error) {
        return {
            response: response || {},
            responseError: error,
            responseStatus,
            metaData: {
                brandId: {},
            },
            headerAndSEOData: {},
            paginationData: {},
            footerContent: {},
            footerAd: {},
            noProductsContent: {},
            mainContent: {},
            config: {
                head: {
                    ...state?.config?.head,
                },
            },
            loading: {
                loadingRequest: true,
                pidOnly: false,
            },
            filters: [
                {
                    values: [],
                },
                {
                    values: [],
                },
                {
                    values: [],
                },
            ],
            orderBy: [],
            isDefaultOrderBy,
            isPageVisible: true,
            legacyRedirect,
            legacyRedirectUrl,
            history: props.history,
            prevPath,
            productLists: [
                {
                    products: new Array(60).fill(),
                    loading: true,
                    key: `${location?.pathname}${location?.search}`,
                },
            ],
            siblingsProducts: [],
            firstFetchedPageNumber: 1,
            currentPage,
            loadingPage: currentPage,
            ssr: props.ssr,
        };
    }

    const locale = getLocale(state);

    const previewDate = getPreviewDate(state);
    const isPageVisible = getPageVisibility(response.body?.selectedCategory, previewDate);
    const { selectedCategories = [], pageType = '' } = getSelectedCategory(state, props.routeKey);
    const saleExclusive = response.body?.selectedCategory?.saleExclusive;
    // TODO: const primaryCategorySeoURLKeyword = selectedCategories?.[0]?.seo?.seoURLKeyword after legacynap removed
    const primaryCategorySeoURLKeyword = response.body?.selectedCategory?.seo?.seoURLKeyword;
    const plpUrlTemplate = state?.config?.global?.urlConfig?.plp?.construct;

    const selectedCategorySeoURLKeyword = selectedCategories[selectedCategories.length - 1]?.seo?.seoURLKeyword;

    const seoRedirect = getSeoRedirect(pageType, appLoading, selectedCategorySeoURLKeyword, match.params.SEO_KEYWORDS, locale, plpUrlTemplate);
    const shouldRedirect = checkRedirect(response.redirectUrl, response.status);
    const searchRedirect = getSearchRedirect(response, locale, state, plpUrlTemplate);
    const saleRedirect = getSaleRedirect(pageType, isPageVisible, match.params.SEO_KEYWORDS, primaryCategorySeoURLKeyword, locale, plpUrlTemplate);

    if (shouldRedirect || searchRedirect || saleRedirect || seoRedirect) {
        return {
            shouldRedirect: true,
            redirectUrl: response.redirectUrl || searchRedirect || saleRedirect || seoRedirect,
            redirectStatus: searchRedirect || saleRedirect || seoRedirect ? 302 : response.status,
            appLoading,
            loading,
            config: state.config,
            response,
            prevPath,
        };
    }

    const searchTermView = getSearchTermView(state);
    const noIndex = searchTermView?.searchProductCount === 0;

    const filters = getFilters(state, props);
    const categoryLabels = getCategoryLabels(state, selectedCategories, pageType, state?.config?.head?.plp?.facet, isTONMenswear);
    const paginationData = getPaginationData(state);
    const headerAndSEOData = getHeaderAndSEOData(selectedCategories, pageType, props.messages, state.config, country);
    const content = getContent(state);
    const footerContent = content.contentBottom;
    const founditContent = getFounditContent(state.plp.founditContent);
    const footerAd = content.footerAd;
    const mainContent = content.contentMain;
    const emptyListContent = content.emptyListContent;
    const noProductsContent = content.contentNoProducts;
    const contentLoading =
        content?.coremediaPageKey && decodeURIComponent(match?.params[pathParam]?.replace(/\//g, '-')) !== decodeURIComponent(content.coremediaPageKey);
    const carouselContent = !contentLoading ? content.contentCarousel : {};
    const visibilityDates = response.body.selectedCategory?.visibility;

    const enableAlgorithmicSort = state?.config?.head?.plp?.enableAlgorithmicSortPageTypes?.includes(pageType) || false;
    const isFetchingOptimizelyAlgorithmicSort = enableAlgorithmicSort && !optimizelyAlgorithmicSortReady;

    const forceLogin = getForceLogin(response);
    const loadingState = getLoading(
        loading,
        forceLogin,
        user,
        state?.plp?.listing?.fetchingMore,
        response.body.restrictedToSegments,
        isPageVisible,
        isFetchingOptimizelyAlgorithmicSort,
    );
    const headerAndSEODataWithContent = getContentHeader(
        headerAndSEOData,
        content.contentTop,
        content.contentTopNav,
        state.config,
        props.messages,
        content.coremediaPageKey,
        contentLoading,
        country,
    );
    const productLists = getProductLists(state, props, loadingState);
    const designerSaleToggleData = {
        ...(response?.body?.toggleToSaleCategory || response?.body?.toggleToFullPriceCategory),
        pageType,
    };

    const productsToPreload = productLists
        .find((pl) => pl.key === state?.plp?.listing?.response?.path)
        ?.products.slice(0, state?.config?.head?.plp?.preloadedProductItems || 12);
    const metaData = getMetaData(
        state.config,
        locale,
        headerAndSEODataWithContent,
        currentPage,
        noIndex,
        productsToPreload,
        content.seo,
        content?.contentImagesToPreload,
    );
    const enableLoadMore =
        //Show loadMore for all pages inside that country
        state?.config?.head?.plp?.loadMore?.countries?.includes(country.toLowerCase()) ||
        //Show loadMore for all pages inside that category
        state?.config?.head?.plp?.loadMore?.categories?.find((c) => c === response.body.selectedCategory?.seo?.seoURLKeyword) ||
        //Show loadMore for specific subCategory
        state?.config?.head?.plp?.loadMore?.subCategories?.find((c) => c === selectedCategories?.[1]?.seo?.seoURLKeyword)
            ? true
            : false;

    // if DLP (designer page), get the designerId from selected category attributes
    const brandAttribute = String(pageType).includes('DESIGNER') && selectedCategories?.[0]?.attributes?.find((attr) => attr.identifier === 'Brand');
    const designerId = brandAttribute?.values?.[0]?.identifier;

    const enableWebpush = shouldEnableWebpush(state, country, pageType);

    const isEipPreviewNoProduct =
        pageType === 'EIP_PREVIEW' && productLists[0].products.length === 0 && (!response.body.facets || response.body.facets.length === 0);

    const schemaMetaData = state?.config?.head?.seo?.useJsonLd && getPLPSchemaMetaData(config, productLists[0].products, metaData, response?.body);

    return {
        filters,
        productLists,
        designerSaleToggleData,
        orderBy: getOrderBy(state),
        isDefaultOrderBy,
        categoryLabels,
        pageType,
        isPageVisible,
        isEipPreviewNoProduct,
        metaData,
        schemaMetaData,
        restrictedToSegments: response.body.restrictedToSegments,
        paginationData,
        productImageAspectRatio: state.config.head.aspectRatio.productImage,
        config: state.config,
        headerAndSEOData: headerAndSEODataWithContent,
        responseError: error,
        response,
        responseStatus,
        footerContent,
        founditContent,
        footerAd,
        emptyListContent,
        noProductsContent,
        mainContent,
        carouselContent,
        pathParam: props.pathParam,
        locale,
        loading: loadingState,
        appLoading,
        user,
        forceLogin,
        notification,
        legacyRedirect,
        legacyRedirectUrl,
        searchTerm: searchTermView?.searchTerm,
        shouldRedirect,
        redirectUrl: response.redirectUrl,
        redirectStatus: response.status,
        selectedCategories,
        history: props.history,
        prevPath,
        loadingPage: state?.plp?.listing?.loadingPage,
        firstFetchedPageNumber: state?.plp?.listing?.firstFetchedPageNumber,
        lastFetchedPageNumber: state?.plp?.listing?.lastFetchedPageNumber,
        currentPage,
        enableLoadMore,
        isFetchingOptimizelyAlgorithmicSort,
        optimizelyAlgorithmicSortReady,
        clickedPidOnPLP: state?.appState?.clickedPidOnPLP,
        scrollToPid: state?.plp?.listing?.scrollToPid,
        updatePLPPageNumber: state?.plp?.listing?.updatePLPPageNumber,
        windowPosition: state?.plp?.listing?.windowPosition,
        loadPrevious: state?.plp?.listing?.loadPrevious,
        loadMoreMaxPages: state?.config?.head?.plp?.loadMore?.maxPages,
        ssr: props.ssr,
        visibilityDates,
        emptyListGoToUrl: (pageType === 'MARK_DOWN' || pageType === 'DESIGNER') && state?.config?.head?.plp?.emptyListLink,
        designerId,
        enableWebpush,
        country,
        isTONMenswear,
        saleExclusive,
        siblingsProducts,
    };
}

const mapDispatchToProps = (dispatch, ownProps) => ({
    fetchListing: (...args) => dispatch(ownProps.action(...args)),
    fetchMoreListing: (...args) => dispatch(fetchMore(...args)),
    updateSelectedSchema: (...args) => dispatch(updateSelectedSchema(...args)),
    fetchUserFromSF: (...args) => dispatch(fetchUserFromSF(...args)),
    checkViewPagePermissions: (...args) => dispatch(checkViewPagePermissions(...args)),
    updatePageNumber: (...args) => dispatch(updatePageNumber(...args)),
    unsetSrcoll: (...args) => dispatch(unsetSrcoll(...args)),
    setWindowPosition: (...args) => dispatch(setWindowPosition(...args)),
    setOptimizelyAlgorithmicSortReady: (...args) => dispatch(setOptimizelyAlgorithmicSortReady(...args)),
    fetchProductSiblings: (...args) => dispatch(fetchProductSiblings(...args)),
});

@track({ lpTag: { additionalSections: ['listing page'] } })
export class PLPContainer extends Component {
    constructor(props) {
        super(props);
        this.containerGenerateListingUrlWithState = this.containerGenerateListingUrlWithState.bind(this);
        this.fetchMoreListing = this.fetchMoreListing.bind(this);
        this.state = {
            listingHeight: [],
            algorithmicSortParams: undefined,
        };
        this.setListingHeight = this.setListingHeight.bind(this);
    }

    setListingHeight(height, index) {
        // TODO - should not modify state directly, Used for scroll position, move to PLP component?
        // eslint-disable-next-line react/no-direct-mutation-state
        this.state.listingHeight[index] = height;
    }

    componentDidMount() {
        const {
            config,
            location: { pathname, search },
            response,
            responseError,
            user,
            forceLogin,
            fetchUserFromSF,
            checkViewPagePermissions,
            pageType,
            restrictedToSegments,
            isPageVisible,
            isEipPreviewNoProduct,
            messages,
            legacyRedirect,
            enableLoadMore,
            updatePLPPageNumber,
            updatePageNumber,
            productLists,
            visibilityDates,
            categoryLabels,
            enableWebpush,
            optimizely,
            optimizelyAlgorithmicSortReady,
            setOptimizelyAlgorithmicSortReady,
            designerId,
            locale,
        } = this.props;

        if (!user) {
            // Always need to know who the user is to be able to determine which segmented badges
            // to potentially show
            fetchUserFromSF(false);
        }

        const whatsNewUrl = config.global && config.global.urlConfig.whatsnew.construct;
        setOptimizelyAlgorithmicSortReady(false);
        //to prevent fetching /Desginers/xxx right after redirect
        if (legacyRedirect) {
            return;
        }

        const allowList = config.head.routesAllowlist?.queries;

        //if the listing requires login and the data is already fetched
        if (forceLogin && dataFetched(productLists, `${pathname}${search}`, allowList)) {
            if (!user) return fetchUserFromSF(forceLogin, { mode: 'restrictedPage' });
            else {
                const authenticated = checkViewPagePermissions(
                    user,
                    pageType,
                    isPageVisible,
                    restrictedToSegments,
                    whatsNewUrl,
                    messages,
                    visibilityDates,
                    isEipPreviewNoProduct,
                )?.authenticated;
                restrictedToSegments && authenticated && trackPageView(this.props);
                return;
            }
        }

        if (
            shouldRefetch({
                url: decodeURI(response.path),
                newUrl: decodeURI(pathname + search),
                allowList,
            })
        ) {
            try {
                return this.buildListingQueryAndFetch();
            } catch (e) {
                raygun.helpers.agent('send', {
                    e,
                    tags: ['plpDecodeURI'],
                });
            }
        }

        if (!optimizelyAlgorithmicSortReady && optimizely && pageType) {
            const originalUrl = pathname;
            const modifiedUrl = originalUrl?.replace(`/${locale}/`, '');
            optimizely?.onReady?.()?.then?.(() => {
                optimizely?.setUser?.({
                    id: optimizely?.user?.id,
                    attributes: {
                        ...optimizely?.user?.attributes,
                        view_plp_type: pageType.toLowerCase(),
                        ...(pageType === 'DESIGNER' ? { designer: designerId?.toLowerCase() || null } : {}),
                        category: getCategory(categoryLabels, pageType)?.subCategory2 || null,
                        url: modifiedUrl || null,
                    },
                });
            });

            optimizely.onUserUpdate(() => {
                setOptimizelyAlgorithmicSortReady(true);
                this.setAlgorithmicSortParams();
            });
        } else {
            optimizely?.onReady?.()?.then?.(() => {
                optimizely?.setUser?.({
                    id: optimizely?.user?.id,
                    attributes: {
                        ...optimizely?.user?.attributes,
                        category: getCategory(categoryLabels, pageType)?.subCategory2 || null,
                    },
                });
            });
        }

        //if page number needs to be updated, push history to new page number
        if (enableLoadMore && updatePLPPageNumber) {
            updatePageNumber();
        }

        // Fire analytics - Will only reach if state matches page data.
        if (!responseError) {
            window.performance.mark('spaIntTime');
            trackPageView(this.props);
            if (optimizely) {
                trackPageOptimizely(optimizely, 'plp');
            }
        }

        if (pageType === 'DESIGNER' && config?.head?.daz?.favouriteDesignerEnabled && !user) {
            fetchUserFromSF();
        }
        if (enableWebpush) {
            triggerWebpushNotification({
                primaryCategory: 'listing page',
                subCategory:
                    pageType === 'JUST_IN' || pageType?.includes('MARK_DOWN')
                        ? getCategory(categoryLabels, pageType)?.subCategory1
                        : getCategory(categoryLabels, pageType)?.subCategory2,
            });
        }
    }

    componentDidUpdate(prevProps, prevState = {}) {
        // Use location for query params so can do string comparision
        // Using this.props.listingState.response.path causes infinite loop
        const {
            config,
            location: { pathname, search },
            response,
            responseError,
            user,
            forceLogin,
            fetchUserFromSF,
            checkViewPagePermissions,
            pageType,
            restrictedToSegments,
            isPageVisible,
            isEipPreviewNoProduct,
            messages,
            legacyRedirect,
            shouldRedirect,
            selectedCategories,
            locale,
            loading,
            history,
            enableLoadMore,
            clickedPidOnPLP,
            unsetSrcoll,
            setClickedPidOnPLP,
            scrollToPid,
            productLists,
            loadPrevious,
            setWindowPosition,
            visibilityDates,
            optimizely,
            optimizelyAlgorithmicSortReady,
            setOptimizelyAlgorithmicSortReady,
            designerId,
            categoryLabels,
            siblingsProducts,
        } = this.props;

        const { pathname: prevPathname, search: prevSearch } = prevProps.location;
        const {
            response: prevResponse,
            user: prevUser,
            forceLogin: prevForceLogin,
            loading: prevLoading,
            productLists: preProductLists,
            pageType: prevPageType,
        } = prevProps;
        const whatsNewUrl = config.global && config.global.urlConfig.whatsnew.construct;
        const { algorithmicSortParams: prevAlgorithmicSortParams } = prevState;

        if (enableLoadMore) {
            //need to manual scroll for load previous
            if (loadPrevious && preProductLists.length !== productLists.length) {
                setWindowPosition(window.scrollY);
            }

            //when scrollToPid is set to true
            //1. back from PDP
            //2. after recycling pids
            if (scrollToPid) {
                //back from PDP
                if (clickedPidOnPLP) {
                    const ele = document.querySelector(`#pid-${clickedPidOnPLP}`);
                    if (ele) {
                        scrollIntoView(ele, undefined, 'auto');
                    }
                    unsetSrcoll();
                    setClickedPidOnPLP();
                    return;
                } else {
                    //after recycling pids
                    //if it's load more, just scroll to the position that is set above
                    //if it's load previous, need to scroll to the position with the new height added to give a 'load previous' feel
                    window.scrollTo(0, this.state.listingHeight[0]);
                    unsetSrcoll();
                    if (this.shouldFireAnalytics(response, prevResponse, responseError, productLists)) {
                        // Fire analytics - navigation change, api returned
                        window.performance.mark('spaIntTime');
                        trackPageView(this.props);
                    }
                    return;
                }
            }
        }

        selectedCategories &&
            clientSideRedirect(
                history,
                selectedCategories,
                response.redirected,
                locale,
                config?.global?.urlConfig?.plp?.construct,
                prevLoading?.loadingRequest,
                loading.loadingRequest,
            );

        //to prevent fetching /Desginers/xxx right after redirect
        if (legacyRedirect || shouldRedirect) {
            return;
        }

        if ((forceLogin && forceLogin !== prevForceLogin) || (forceLogin && user?.parentCustomerURN !== prevUser?.parentCustomerURN)) {
            if (!user) {
                fetchUserFromSF(forceLogin, { mode: 'restrictedPage' });
            } else {
                const authenticated = checkViewPagePermissions(
                    user,
                    pageType,
                    isPageVisible,
                    restrictedToSegments,
                    whatsNewUrl,
                    messages,
                    visibilityDates,
                    isEipPreviewNoProduct,
                )?.authenticated;
                restrictedToSegments && authenticated && trackPageView(this.props);
                return;
            }
        }

        /* istanbul ignore next - Mocking and testing all props + pageType changing challenging */
        if (!optimizelyAlgorithmicSortReady && optimizely && pageType !== prevPageType) {
            optimizely?.onReady?.()?.then?.(() => {
                optimizely?.setUser?.({
                    id: optimizely?.user?.id,
                    attributes: {
                        ...optimizely?.user?.attributes,
                        view_plp_type: pageType?.toLowerCase() || null,
                        ...(pageType === 'DESIGNER' ? { designer: designerId?.toLowerCase() || null } : { designer: null }),
                        category: getCategory(categoryLabels, pageType)?.subCategory2 || null,
                    },
                });
            });

            optimizely.onUserUpdate(() => {
                setOptimizelyAlgorithmicSortReady(true);
                this.setAlgorithmicSortParams();
            });
        }

        if (optimizely && pathname !== prevPathname) {
            const originalUrl = pathname;
            const modifiedUrl = originalUrl?.replace(`/${locale}/`, '');
            optimizely?.onReady?.()?.then?.(() => {
                optimizely?.setUser?.({
                    id: optimizely?.user?.id,
                    attributes: {
                        ...optimizely?.user?.attributes,
                        url: modifiedUrl || null,
                    },
                });
            });
        }

        if (prevAlgorithmicSortParams !== this.state.algorithmicSortParams) {
            return this.buildListingQueryAndFetch(this.prop);
        }

        if (enableLoadMore) {
            if (
                !shouldRefetch({
                    url: decodeURI(response.path),
                    newUrl: decodeURI(pathname + search),
                    pls: this.props.productLists,
                    pageKey: decodeURI(pathname + search),
                    allowList: config.head.routesAllowlist?.queries,
                })
            ) {
                if (this.shouldFireAnalytics(response, prevResponse, responseError)) {
                    window.performance.mark('spaIntTime');
                    // Fire analytics - navigation change, api returned
                    trackPageView(this.props);
                    if (optimizely) {
                        trackPageOptimizely(optimizely, 'plp');
                    }
                }
                return;
            }
        }

        if (search !== prevSearch || pathname !== prevPathname) {
            return this.buildListingQueryAndFetch(this.props);
        }

        if (this.shouldFireAnalytics(response, prevResponse, responseError)) {
            window.performance.mark('spaIntTime');
            // Fire analytics - navigation change, api returned
            trackPageView(this.props);
        }

        const shouldShowSwatches = config?.head?.plp?.colourSwatches;
        const isLoading = loading?.loadingRequest || productLists[0]?.loading;
        const haveLoadedMoreProducts = prevLoading?.loadingRequest;
        const isFirstProductsLoad = productLists[0]?.products?.length && !siblingsProducts?.products;

        if (shouldShowSwatches && !isLoading && (isFirstProductsLoad || haveLoadedMoreProducts)) {
            const productsWithSiblings = productLists[0].products.filter((p) => p.hasSiblings);
            if (productsWithSiblings?.length) this.props.fetchProductSiblings(productsWithSiblings.map((p) => p.partNumber));
        }
    }

    shouldFireAnalytics(response, prevResponse, responseError) {
        if (response && prevResponse && !responseError) {
            if (response.path !== prevResponse.path) {
                return true;
            }
        }
        return false;
    }

    buildListingQueryAndFetch(props = this.props) {
        const { pathname, search } = props.location;
        const { protocol, host } = window?.location || {};
        const hostname = `${protocol}//${host}` || '';
        const seoPath = props.match.params[props.pathParam];
        const queryParams = parse(search);
        const algorithmicSortParams = parse(this.state.algorithmicSortParams) || {};
        const { itemsPerPage: pageSize, contentOverride, useContentByUrl } = props?.config?.head?.plp || {};

        const defaultParams = {
            pageNumber: 1,
            pageSize: pageSize || 60,
            attrs: 'true',
        };
        const combinedParams = Object.assign(defaultParams, queryParams, props.fetchParams ? props.fetchParams(queryParams) : {}, algorithmicSortParams);
        const originalPath = useContentByUrl ? pathname : undefined;
        this.props.fetchListing(seoPath, combinedParams, pathname + search, contentOverride, props.user, originalPath, hostname).catch((err) => {
            return err;
        });
    }

    fetchMoreListing(previous) {
        const { location, firstFetchedPageNumber, lastFetchedPageNumber, match, pathParam, user, pageType, setWindowPosition, fetchParams } = this.props;
        const { pathname, search } = location;
        const seoPath = match.params[pathParam];
        const queryParams = parse(search);
        const algorithmicSortParams = parse(this.state.algorithmicSortParams) || {};
        const pageSize = this.props?.config?.head?.plp?.itemsPerPage;
        const { totalPages } = this.props?.paginationData || {};

        if (previous) {
            queryParams.pageNumber = firstFetchedPageNumber > 1 ? firstFetchedPageNumber - 1 : 1;
        } else {
            queryParams.pageNumber = lastFetchedPageNumber >= totalPages ? totalPages : lastFetchedPageNumber + 1;
        }

        const defaultParams = {
            pageSize: pageSize || 60,
            attrs: 'true',
        };

        setWindowPosition(window.scrollY);

        const combinedParams = Object.assign(defaultParams, queryParams, fetchParams ? fetchParams(queryParams) : {}, algorithmicSortParams);
        this.props.fetchMoreListing(seoPath, combinedParams, pathname + '?' + stringify(queryParams), previous, user, pageType).catch((err) => err);
    }

    containerGenerateListingUrlWithState(locationState) {
        const { location, match, pathParam } = this.props;
        return (urlGenerateObject) => {
            return { ...generateListingUrl(urlGenerateObject, location, match, pathParam), state: locationState };
        };
    }

    setAlgorithmicSortParams() {
        const { optimizely } = this.props;
        if (optimizely) {
            const enabledFeature = ['web00367_mrpdatasciencediversitypinning_plp_all', 'web00368_napdatasciencediversitypinning_plp_all'].find((feature) =>
                optimizely.isFeatureEnabled(feature),
            );
            if (!enabledFeature) {
                return setOptimizelyAlgorithmicSortReady(true);
            }
            const { variables } = optimizely?.userContext?.decide(enabledFeature) || {};
            if (!this.state.algorithmicSortParams && variables) {
                this.setState({
                    algorithmicSortParams: variables.query,
                });
            }
        }
    }

    render() {
        const {
            messages,
            filters,
            updateSelectedSchema,
            paginationData,
            location,
            orderBy,
            isDefaultOrderBy,
            productLists,
            designerSaleToggleData,
            productImageAspectRatio,
            config,
            headerAndSEOData,
            footerContent,
            footerAd,
            emptyListContent,
            noProductsContent,
            mainContent,
            carouselContent,
            founditContent,
            responseError,
            responseStatus,
            loading,
            pageType,
            isPageVisible,
            pathParam,
            filterCategoryByPath,
            notification,
            forceLogin,
            metaData,
            schemaMetaData,
            locale,
            legacyRedirect,
            legacyRedirectUrl,
            shouldRedirect,
            redirectUrl,
            redirectStatus,
            prevPath,
            loadingPage,
            enableLoadMore,
            firstFetchedPageNumber,
            lastFetchedPageNumber,
            currentPage,
            selectedCategories,
            ssr,
            emptyListGoToUrl,
            categoryLabels,
            user,
            designerId,
            isFetchingOptimizelyAlgorithmicSort,
            country,
            language,
            saleExclusive,
        } = this.props;

        const internal_urls = ['pdp', 'plp', 'azdesigners', 'whatsnew'];

        const favouriteDesignerEnabled = config?.head?.daz?.favouriteDesignerEnabled || false;
        const designerIdentifier = favouriteDesignerEnabled && !loadingPage && designerId;
        const headerActions = designerIdentifier ? (
            <FavouriteDesigner
                designer={designerIdentifier}
                text={messages.designers.favorite()}
                analytics={{ pageType, categoryLabels }}
                messages={messages}
            />
        ) : undefined;

        // this is to handle old PDP urls on the new site
        // TODO remove post migration
        if (legacyRedirect) {
            return (
                <Redirect
                    to={{
                        pathname: legacyRedirectUrl,
                        search: location.search,
                        state: { statusCode: config?.head?.redirects?.statusCode || 302 },
                    }}
                />
            );
        }

        if (shouldRedirect && redirectStatus) {
            return (
                <Redirect
                    to={{
                        pathname: redirectUrl,
                        search: location.search,
                        state: { statusCode: redirectStatus },
                    }}
                />
            );
        }

        if (responseError) {
            if (responseStatus >= 400 && responseStatus < 500) {
                return (
                    <StatusPage code={responseStatus} displayMessage={messages.error.notFound()} actionLabel={messages.error.goBack()} prevPath={prevPath} />
                );
            } else {
                return (
                    <RetryErrorPage
                        messages={messages}
                        maxRetries={config.head.errorPage.maxRetries}
                        backoff={config.head.errorPage.backoff}
                        action={() => this.buildListingQueryAndFetch(this.props)}
                    />
                );
            }
        }

        return (
            <FeatureSelectorProviderWrapper features={featuresList} categories={selectedCategories ? selectedCategories.map((cat) => cat.identifier) : []}>
                {notification && notification.name === 'action' && (
                    <PLPNotify
                        name={notification.name}
                        type={notification.type}
                        title={notification.title}
                        message={notification.message}
                        messages={messages}
                    />
                )}
                <MetaTags schemaMetaData={schemaMetaData} metaData={metaData} script={config.head?.plp.optimizely} toPreconnect={config.head?.preconnectTags} />
                {pageType === 'DESIGNER' && favouriteDesignerEnabled && user && (
                    <Onboarding messages={messages} localStorageKey="DLPFavouriteDesignerOverlayViewed" />
                )}
                <ProductListingPage
                    generateListingUrlWithState={this.containerGenerateListingUrlWithState}
                    productLists={productLists}
                    designerSaleToggleData={designerSaleToggleData}
                    sortOptions={orderBy}
                    isDefaultOrderBy={isDefaultOrderBy}
                    getFilterGroup={getFilterGroup}
                    paginationData={paginationData}
                    messages={messages}
                    isPageVisible={isPageVisible}
                    filters={filters}
                    location={location}
                    updateSelectedSchema={updateSelectedSchema}
                    accordionAnimation={true}
                    loading={loading}
                    productImageAspectRatio={productImageAspectRatio}
                    // headerData={headerAndSEOData.title === 'Sale' ? {...headerAndSEOData, saleTitle: 'Promotion', title: 'Promotion'} : headerAndSEOData}
                    headerData={headerAndSEOData}
                    footerData={footerContent}
                    footerAd={footerAd}
                    emptyListContent={emptyListContent}
                    noProductsData={noProductsContent}
                    mainContent={mainContent}
                    carouselContent={carouselContent}
                    founditContent={founditContent}
                    key={this.key}
                    disableSPA={config.head.disableSPA}
                    pathParam={pathParam}
                    filterCategoryByPath={filterCategoryByPath}
                    pageType={pageType}
                    forceLogin={forceLogin}
                    urlConfig={config.global?.urlConfig || {}}
                    enableLoadMore={enableLoadMore}
                    isFetchingOptimizelyAlgorithmicSort={isFetchingOptimizelyAlgorithmicSort}
                    internal_urls={internal_urls}
                    locale={locale}
                    metaData={metaData}
                    baseUrl={config.head?.seo?.baseUrl}
                    facetConfig={config.head?.plp?.facet}
                    fetchMoreListing={this.fetchMoreListing}
                    loadingPage={loadingPage}
                    currentPage={currentPage}
                    firstFetchedPageNumber={firstFetchedPageNumber}
                    lastFetchedPageNumber={lastFetchedPageNumber}
                    setListingHeight={this.setListingHeight}
                    ssr={ssr}
                    preloadedProductItems={config?.head?.plp?.preloadedProductItems}
                    productReplacementConfig={config.head?.plp?.productReplacement}
                    imageQualityPercentage={config.head?.imageQualityPercentage}
                    luxWatchesImageQualityPercentage={config.head?.luxWatchesImageQualityPercentage}
                    zoomedImageQualityPercentage={config.head?.zoomedImageQualityPercentage}
                    imageSrcsetSizes={config?.head?.plp?.imageSrcsetSizes}
                    emptyListGoToUrl={emptyListGoToUrl}
                    useIdentifier={config?.head?.useIdentifier}
                    headerActions={headerActions}
                    country={country}
                    language={language}
                    config={config}
                    saleExclusive={saleExclusive}
                    user={user}
                />
            </FeatureSelectorProviderWrapper>
        );
    }

    static propTypes = {
        appLoading: PropTypes.bool.isRequired,
        carouselContent: PropTypes.object,
        checkViewPagePermissions: PropTypes.func.isRequired,
        config: PropTypes.object.isRequired,
        designerSaleToggleData: PropTypes.shape({
            label: PropTypes.string,
            seo: PropTypes.shape({
                seoURLKeyword: PropTypes.string,
            }),
        }),
        emptyListContent: PropTypes.object,
        enableLoadMore: PropTypes.bool,
        error: PropTypes.bool.isRequired,
        fetchListing: PropTypes.func.isRequired,
        fetchUserFromSF: PropTypes.func.isRequired,
        filterCategoryByPath: PropTypes.string,
        filters: PropTypes.array,
        footerContent: PropTypes.object,
        footerAd: PropTypes.object,
        forceLogin: PropTypes.bool,
        founditContent: PropTypes.object,
        headerAndSEOData: PropTypes.shape({
            title: PropTypes.string,
            subTitle: PropTypes.string,
            copy: PropTypes.string,
            numberOfItems: PropTypes.string,
            seo: PropTypes.object,
        }),
        history: PropTypes.func.isRequired,
        isEipPreviewNoProduct: PropTypes.bool,
        isPageVisible: PropTypes.bool,
        legacyRedirectUrl: PropTypes.string,
        legacyRedirect: PropTypes.bool,
        listingState: PropTypes.shape({
            response: PropTypes.shape({
                path: PropTypes.string.isRequired,
            }),
            loading: PropTypes.string.isRequired,
        }),
        loading: PropTypes.object,
        locale: PropTypes.string,
        location: PropTypes.shape({
            pathname: PropTypes.string.isRequired,
            search: PropTypes.string.isRequired,
        }),
        mainContent: PropTypes.object,
        match: PropTypes.object.isRequired,
        messages: PropTypes.object.isRequired,
        metaData: PropTypes.object,
        noProductsContent: PropTypes.object,
        notification: PropTypes.shape({
            name: PropTypes.string,
            type: PropTypes.string,
            message: PropTypes.string,
            dismissTimer: PropTypes.number,
        }),
        optimizely: PropTypes.object,
        orderBy: PropTypes.array,
        pageType: PropTypes.string,
        paginationData: PropTypes.object,
        pathParam: PropTypes.string.isRequired,
        prevPath: PropTypes.string,
        productLists: PropTypes.array,
        products: PropTypes.array,
        productImageAspectRatio: PropTypes.string,
        redirectUrl: PropTypes.string,
        redirectStatus: PropTypes.string,
        response: PropTypes.object,
        responseError: PropTypes.object,
        restrictedToSegments: PropTypes.array,
        ssr: PropTypes.bool.isRequired,
        shouldRedirect: PropTypes.bool,
        selectedCategories: PropTypes.array.isRequired,
        tracking: PropTypes.shape({
            trackEvent: PropTypes.func.isRequired,
        }),
        updatePageNumber: PropTypes.func,
        updatePLPPageNumber: PropTypes.bool,
        updateSelectedSchema: PropTypes.func.isRequired,
        user: PropTypes.object,
    };
}

export const PLPContainerWithConnect = connect(mapStateToProps, mapDispatchToProps)(PLPContainer);
export default withOptimizely(PLPContainerWithConnect);
