import React, { Suspense, memo, useState, useEffect } from 'react';
import BEMHelper from '@ynap/bem-helper';
import { useConfig } from '@ynap/immutables-provider';
import { ProductsImageWithSkeleton } from '@ynap/image';
import { OptimizelyFeature } from '@ynap/optimizely-utils';
import Colours from '../Colours/Colours';
import { Features, FeatureSelectorProviderWrapper } from '@ynap/feature-selector';
import featuresList from 'app/features/featuresList';

const AddWishlistButton = React.lazy(() => import(/* webpackChunkName: "AddWishlistButton" */ '../AddWishlistButton/AddWishlistButton'));

const ProductList = (props) => {
    const bem = new BEMHelper('ProductList');
    const {
        ProductSummary,
        products,
        preloadedProductItems = 12,
        productImageAspectRatio,
        imageSrcsetSize,
        productListKey,
        messages,
        loading,
        disableSPA,
        locale,
        baseUrl,
        ssr,
        wishlist,
        listingPage,
        country,
        loadingColors,
    } = props;
    const { head: { useIdentifier, minimumPriceCountries = [], plp, seo } = {} } = useConfig();
    const isEUcountry = minimumPriceCountries.includes(country?.toUpperCase?.());
    const [userSummary, setUserSummary] = useState(null);
    const showWishlistButtonFeature = plp?.addWishlist && userSummary;

    useEffect(() => {
        const getUserSummary = async () => {
            const userSummary = await window.SF?.getUserSummary();
            setUserSummary(userSummary);
        };
        getUserSummary();
    }, []);
    return products ? (
        <>
            <OptimizelyFeature
                feature={[
                    'WEB00046_ATWfromPLP_PLP_desktop',
                    'WEB00078_ATWfromPLP_PLP_mobile',
                    'WEB00127_ATWfromPLP_PLP_desktop',
                    'WEB00128_ATWfromPLP_PLP_mobile',
                    'web00579_addtowishlist_plp_desktop',
                    'web00580_addtowishlist_plp_mobile'
                ]}
            >
                {({ variationKey }) => {
                    const showWishlistButtonExp = ['v1_web00046_atw', 'v1_web00078_atw', 'v1_web00127_atw', 'v1_web00128_atw', 'v1_add_to_wishlist_plp'].includes(variationKey);
                    const showLoginForm = ['v1_add_to_wishlist_plp'].includes(variationKey);

                    const showSwatches = !!plp?.colourSwatches;

                    return products.map((product, i) => (
                        <div key={i} className={bem('productItemContainer')}>
                            {!!(showWishlistButtonExp || showWishlistButtonFeature) && (
                                <Suspense fallback={''}>
                                    <AddWishlistButton wishlist={wishlist} product={product} locale={locale} messages={messages} showLoginForm={showLoginForm} />
                                </Suspense>
                            )}
                            <ProductSummary
                                withIntersectionObserver={i > preloadedProductItems} //remove intersection observer for first 12 products
                                withSkeleton={ssr ? (i > preloadedProductItems ? true : false) : true} //remove skeleton for first 12 products on server side render only
                                className={bem('productItem')}
                                productImageAspectRatio={productImageAspectRatio}
                                key={`${productListKey}-${i}`}
                                position={i + 1}
                                product={product}
                                messages={messages}
                                loading={loading}
                                imageSrcsetSize={imageSrcsetSize}
                                nativelink={disableSPA ? true : undefined}
                                locale={locale}
                                baseUrl={baseUrl}
                                id={`pid-${product?.partNumber}`}
                                useIdentifier={useIdentifier}
                                ImageComponent={ProductsImageWithSkeleton}
                                listingPage={listingPage}
                                isEUcountry={isEUcountry}
                                variation={variationKey}
                                country={country}
                                addMicrodata={true}
                            />
                            {showSwatches && (
                                <FeatureSelectorProviderWrapper features={featuresList} categories={[product?.masterCategory?.identifier]}>
                                    <Features>
                                        <Colours
                                            colours={product?.productSiblings}
                                            className="colour"
                                            product={product}
                                            loading={loadingColors}
                                            activeForm={'active'}
                                            useJsonLd={seo?.useJsonLd}
                                        />
                                    </Features>
                                </FeatureSelectorProviderWrapper>
                            )}
                        </div>
                    ));
                }}
            </OptimizelyFeature>
        </>
    ) : null;
};

const areEqual = (prevProps, nextProps) => {
    if (prevProps.products.length !== nextProps.products.length) {
        return false;
    }

    // badges might update when user data is fetched and segmented badges are shown
    for (let i = 0; i < prevProps.products.length; i++) {
        if (prevProps.products[i]?.badge?.key !== nextProps.products[i]?.badge?.key) {
            return false;
        }
    }

    if (prevProps.loading !== nextProps.loading) {
        return false;
    }

    if (prevProps.productListKey !== nextProps.productListKey) {
        return false;
    }
    if (prevProps.wishlist?.wishList !== nextProps.wishlist?.wishList) {
        return false;
    }

    if (prevProps.loadingColors !== nextProps.loadingColors) {
        return false;
    }

    return true;
};

export default memo(ProductList, areEqual);
